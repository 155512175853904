import BlockQuote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
// Option 2: Browser-only (lightweight)
// import { generateHTML } from '@tiptap/core'
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";

const TIP_TAP_EXTENSIONS = [
  Document,
  Paragraph,
  Text,
  Bold,
  Italic,
  ListItem,
  OrderedList,
  BulletList,
  HardBreak,
  Link.extend({ inclusive: false }).configure({
    openOnClick: false,
    linkOnPaste: true,
    defaultProtocol: "https"
  }),
  BlockQuote,
  Strike,
  Heading,
  HorizontalRule
];

export default TIP_TAP_EXTENSIONS;
